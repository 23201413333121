import {
  ApolloClient,
  InMemoryCache, 
  ApolloProvider,
  ApolloLink,
  HttpLink,
  useQuery, 
  gql
} from '@apollo/client';
import {
  useState, 
  useEffect
} from "react";
import React from "react";
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import EmbedHandler from "./EmbedHandler";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Embed from 'react-embed';
import MobileShare from "./MobileShare";

const httpLink = new HttpLink({ uri: 'https://graphql.contentful.com/content/v1/spaces/rhqfw85lhqhq/' });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = 'j2T5-9mLoJgCEXk2e8-4S6hCyrjklSrFP-5RSFk4KXY';

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
	headers: {
	  authorization: token ? `Bearer ${token}` : ''
	}
  });
  
  // Call the next link in the middleware chain.
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink), // Chain it with the HttpLink
  cache: new InMemoryCache()
});

const GetThePosts = gql`
query CollectionTag($theSlug: String!) {
	postCollection(
		  limit: 1
		  where: { 
			slug: $theSlug	
		  }
		) {
	items {
		  sys {
			id
		  }
		  title
		  captionBody
		  publishDate
		  linkLabel
		  postLink
		  embedLink
		  imagesCollection {
			  items {
			  title
			  url
			  width
			  height
			}
		  }
		}
	  }
	}
`;

function ShowPosts() {
 const { useSlug } = useParams();

  const { loading, error, data } = useQuery(GetThePosts, {
	  variables: { theSlug : useSlug },
	});

  if (loading) return <div className="container mx-auto text-center pt-12">
						  <button type="button" class="bg-black inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white cursor-not-allowed" disabled>
							<svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
							  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
							  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
							</svg>
								Loading
						</button>
					</div>;
					
  if (error) return <pre>Error :( { JSON.stringify(error) }</pre>;

  return data.postCollection.items.map( item => (
		<div className="w-full pt-3 md:pt-6">
		  { /* JSON.stringify(item) */}
			<article className="max-w-xl mx-auto">
			  <div className="feed-item border border-black rounded-lg bg-black">
				<div className="header p-4 flex justify-between items-center">
				  <div className="left flex flex-row items-center">
					<div className="user-name-and-place flex flex-col text-white">
					<span className="text-sm font-bold">{item.title}</span>
					<span className="text-xs font-light text-gray-400"></span>
					</div>
				  </div>
				  <div className="right">
					<MobileShare shareBody={item.captionBody} shareTitle={item.title} />
				  </div>
				</div>
		{/* Check the MF exists and there is ONLY one image */
		item.imagesCollection && item.imagesCollection.items.length === 1 &&
		<div className="feed-img relative">
			<img src={item.imagesCollection.items[0].url} alt={item.imagesCollection.items[0].title} /> 
		</div>
		}
		{/* Check the MF exists and there is MORE THAN one image! */
		item.imagesCollection && item.imagesCollection.items.length > 1 &&
		<Carousel showThumbs={false} dynamicHeight={true}>
		{item.imagesCollection.items.map(item => (
		<div key={item.title}>
			<img
			  src={item.url}
			  alt={item.title}
			/>
			</div>
		)
		)}
		</Carousel>
		}
		{ item.embedLink &&
		<EmbedHandler embedId={item.embedLink} />
		}
		{ item.postLink &&
			<div>
				{  item.linkLabel ?
					<a class="px-4 py-2 bg-red-700 text-white block" href={item.postLink}>
							{item.linkLabel}
					</a>
					: 
					<a class="px-4 py-2 bg-red-700 text-white block" href={item.postLink}>
						Find out more -->
					</a>
				}
			</div>
		}  
				<div className="card-footer p-4 text-white">
				  <div className="top">
					<div className="caption text-sm">
					  <p className="pb-2">{item.captionBody}</p>
					</div>
				  </div>
				</div>
			  </div>
		  </article>
		</div>
  ));
}

export function PostPage() {		
	
  return (
	  <div className="container mx-auto px-4">
			  <ApolloProvider client={client}>
			  <div className="flex flex-wrap -mx-3 lg:-mx-6">
			      <ShowPosts />
			  </div>
			 </ApolloProvider>
	  </div>
  );
}