import React from "react";
import PropTypes from "prop-types";
import Embed from 'react-embed';



const EmbedHandler = ({ embedId }) => (
  
  embedId.match(/^album|^track/) ?
    <div className="media-responsive">
      <iframe
        width="100%"
        height="100%"
        src={`https://bandcamp.com/EmbeddedPlayer/${embedId}/size=large/bgcol=333333/linkcol=e32c14/transparent=true/`}seamless>
      </iframe>
    </div>
   : <Embed isDark url={embedId}  />
  
);

EmbedHandler.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default EmbedHandler;
