import {
  ApolloClient,
  InMemoryCache, 
  ApolloProvider,
  useQuery, 
  gql
} from '@apollo/client';
import {
  useState, 
  useEffect
} from "react";
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
//import logo from './logo.svg';
import { FaRedo } from "react-icons/fa"
//import './App.css';
import { HomePage } from './components/FeedPage';
import { MusicTagPage } from './components/MusicTagPage'
import { VideoTagPage } from './components/VideoTagPage'
import { PodcastTagPage } from './components/PodcastTagPage'
import { ImageTagPage } from './components/ImageTagPage'
import { PostPage } from './components/PostPage'

const query = `
{
  postCollection(
      order: publishDate_DESC,
      limit: 100
    ) {
  items {
      title
      captionBody
      publishDate
      linkLabel
      postLink
      embedLink
      imagesCollection {
        items {
        title
        url
        width
        height
      }
      }
      contentfulMetadata {
      tags {
        name
        id
      }
      }
    }
  }
}`

function App() {
  // define the initial state
  const [page, setPage] = useState(null);
  
  const currUrl = window.location.href;
  
  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/rhqfw85lhqhq/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer j2T5-9mLoJgCEXk2e8-4S6hCyrjklSrFP-5RSFk4KXY",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        setPage(data.postCollection.items);
      });
  }, []);
  
  
  // show a loading screen case the data hasn't arrived yet
  if (!page) {
    return "Loading...";
  }
  
  return (
    
    <div className="App mb-8">
          
        <div className="bg-black p-2">
          <div className="flex justify-between items-center">
            <img alt="Logo" className="w-32 md:w-32 p-2" src="/jazzrefreshed-logo.png" />
            <a href={currUrl} className="" aria-label="Reload Feed">
              <FaRedo className="h-6 w-auto text-white fill-current" />
            </a>
          </div>
        </div>
            
      <Router>
       <div className="mx-auto px-4 sticky top-0 z-10 bg-white filter drop-shadow-sm">
          <div className="max-w-xl pb-4 pt-4 mx-auto flex justify-between align-middle">
          <Link to="/" exact>
            <svg width="30px" height="30px" viewBox="0 0 160 160" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Artboard" transform="translate(-1087.000000, -6050.000000)">
                        <g id="-Round-/-Navigation-/-apps" transform="translate(1047.000000, 6010.000000)">
                            <polygon id="Path" points="0 0 240 0 240 240 0 240"></polygon>
                            <path d="M40,80 L80,80 L80,40 L40,40 L40,80 Z M100,200 L140,200 L140,160 L100,160 L100,200 Z M40,200 L80,200 L80,160 L40,160 L40,200 Z M40,140 L80,140 L80,100 L40,100 L40,140 Z M100,140 L140,140 L140,100 L100,100 L100,140 Z M160,40 L160,80 L200,80 L200,40 L160,40 Z M100,80 L140,80 L140,40 L100,40 L100,80 Z M160,140 L200,140 L200,100 L160,100 L160,140 Z M160,200 L200,200 L200,160 L160,160 L160,200 Z" id="🔹-Icon-Color" fill="#1D1D1D"></path>
                        </g>
                    </g>
                </g>
            </svg>
          </Link>
          <Link to="/tag/video" exact>
           <svg width="30px" height="30px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" >
               <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                   <g id="Artboard" transform="translate(-1492.000000, -6030.000000)">
                       <g id="-Round-/-AV-/-play_circle_outline" transform="translate(1472.000000, 6010.000000)">
                           <rect id="Rectangle-Copy-63" x="0" y="0" width="240" height="240"></rect>
                           <path d="M108,159 L154.7,124 C157.4,122 157.4,118 154.7,116 L108,81 C104.7,78.5 100,80.9 100,85 L100,155 C100,159.1 104.7,161.5 108,159 Z M120,20 C64.8,20 20,64.8 20,120 C20,175.2 64.8,220 120,220 C175.2,220 220,175.2 220,120 C220,64.8 175.2,20 120,20 Z M120,200 C75.9,200 40,164.1 40,120 C40,75.9 75.9,40 120,40 C164.1,40 200,75.9 200,120 C200,164.1 164.1,200 120,200 Z" id="🔹Icon-Color" fill="#1D1D1D"></path>
                       </g>
                   </g>
               </g>
           </svg>
            </Link>
    
            <Link to="/tag/music" exact>
            <div>
              <svg width="30px" height="30px" viewBox="0 0 120 160" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="Artboard" transform="translate(-1957.000000, -6050.000000)">
                          <g id="Group" transform="translate(1897.000000, 6010.000000)">
                              <g id="-Round-/-AV-/-music_video" transform="translate(20.000000, 0.000000)">
                                  <rect id="Rectangle-Copy-47" x="0" y="0" width="240" height="240"></rect>
                                  <path d="M40.6577289,167.199997 C36.257729,141.866664 55.4577285,120 79.9910612,120 C84.6577277,120 89.1910609,120.933331 93.3243941,122.399998 L93.3243941,66.6666659 C93.3243941,52 105.324394,40 119.99106,40 L146.657726,40 C153.991059,40 159.991059,46 159.991059,53.333333 C159.991059,60.6666661 153.991059,66.6666659 146.657726,66.6666659 L119.99106,66.6666659 L119.99106,160.399997 C119.724393,184.666663 97.8577274,203.866662 72.657728,199.333329 C56.5243952,196.533329 43.3243955,183.333329 40.6577289,167.199997 L40.6577289,167.199997 Z" id="🔹Icon-Color" fill="#1D1D1D"></path>
                              </g>
                          </g>
                      </g>
                  </g>
              </svg>
            </div>
            </Link>
          <Link to="/tag/podcast" exact>
          <div>
            <svg width="30px" height="30px" viewBox="0 0 140 190" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Artboard" transform="translate(-2372.000000, -6030.000000)">
                        <g id="-Round-/-AV-/-mic" transform="translate(2322.000000, 6010.000000)">
                            <rect id="Rectangle-Copy-36" x="0" y="0" width="240" height="240"></rect>
                            <path d="M120,140 C136.6,140 150,126.6 150,110 L150,50 C150,33.4 136.6,20 120,20 C103.4,20 90,33.4 90,50 L90,110 C90,126.6 103.4,140 120,140 Z M179.1,110 C174.2,110 170.1,113.6 169.3,118.5 C165.2,142 144.7,160 120,160 C95.3,160 74.8,142 70.7,118.5 C69.9,113.6 65.8,110 60.9,110 C54.8,110 50,115.4 50.9,121.4 C55.8,151.4 79.8,174.9 110,179.2 L110,200 C110,205.5 114.5,210 120,210 C125.5,210 130,205.5 130,200 L130,179.2 C160.2,174.9 184.2,151.4 189.1,121.4 C190.1,115.4 185.2,110 179.1,110 Z" id="🔹Icon-Color" fill="#1D1D1D"></path>
                        </g>
                    </g>
                </g>
            </svg>
          </div>
          </Link>
    
          <Link to="/tag/images" exact>
            <svg width="30px" height="30px" viewBox="0 0 180 180" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Artboard" transform="translate(-2755.000000, -6040.000000)">
                        <g id="-Round-/-Image-/-filter_none" transform="translate(2747.000000, 6032.000000)">
                            <polygon id="Path" points="0 0 196 0 196 196 0 196"></polygon>
                            <path d="M16.3333333,40.8333333 C11.8416667,40.8333333 8.16666667,44.5083333 8.16666667,49 L8.16666667,171.5 C8.16666667,180.483333 15.5166667,187.833333 24.5,187.833333 L147,187.833333 C151.491667,187.833333 155.166667,184.158333 155.166667,179.666667 C155.166667,175.175 151.491667,171.5 147,171.5 L32.6666667,171.5 C28.175,171.5 24.5,167.825 24.5,163.333333 L24.5,49 C24.5,44.5083333 20.825,40.8333333 16.3333333,40.8333333 Z M171.5,8.16666667 L57.1666667,8.16666667 C48.1833333,8.16666667 40.8333333,15.5166667 40.8333333,24.5 L40.8333333,138.833333 C40.8333333,147.816667 48.1833333,155.166667 57.1666667,155.166667 L171.5,155.166667 C180.483333,155.166667 187.833333,147.816667 187.833333,138.833333 L187.833333,24.5 C187.833333,15.5166667 180.483333,8.16666667 171.5,8.16666667 Z M163.333333,138.833333 L65.3333333,138.833333 C60.8416667,138.833333 57.1666667,135.158333 57.1666667,130.666667 L57.1666667,32.6666667 C57.1666667,28.175 60.8416667,24.5 65.3333333,24.5 L163.333333,24.5 C167.825,24.5 171.5,28.175 171.5,32.6666667 L171.5,130.666667 C171.5,135.158333 167.825,138.833333 163.333333,138.833333 Z" id="🔹-Icon-Color" fill="#1D1D1D"></path>
                        </g>
                    </g>
                </g>
            </svg>
          </Link>
        </div>
        </div>
                
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/tag/video" component={VideoTagPage} />
          <Route path="/tag/music" component={MusicTagPage} />
          <Route path="/tag/podcast" component={PodcastTagPage} />
          <Route path="/tag/images" component={ImageTagPage} />
          <Route path="/post/:useSlug" component={PostPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;