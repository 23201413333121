import {useState, useEffect} from "react";
import React from "react";
import EmbedHandler from "./EmbedHandler";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Embed from 'react-embed';
import MobileShare from "./MobileShare";

const query = `
{
  postCollection ( order: [ pinPost_DESC, publishDate_DESC ] ) {
  items {
	  pinPost
	  title
	  captionBody
	  publishDate
	  linkLabel
	  postLink
	  embedLink
	  imagesCollection {
		  items {
		  title
		  url
		  width
		  height
		}
	  }
	  contentfulMetadata {
		tags {
		  name
		  id
		}
	  }
	}
  }
}`

export function HomePage() {
	// define the initial state
	  const [page, setPage] = useState(null);
	  
	  useEffect(() => {
		window
		  .fetch(`https://graphql.contentful.com/content/v1/spaces/rhqfw85lhqhq/`, {
			method: "POST",
			headers: {
			  "Content-Type": "application/json",
			  // Authenticate the request
			  Authorization: "Bearer j2T5-9mLoJgCEXk2e8-4S6hCyrjklSrFP-5RSFk4KXY",
			},
			// send the GraphQL query
			body: JSON.stringify({ query }),
		  })
		  .then((response) => response.json())
		  .then(({ data, errors }) => {
			if (errors) {
			  console.error(errors);
			}
	
			// rerender the entire component with new data
			setPage(data.postCollection.items);
		  });
	  }, []);
	  
	  
// show a loading screen case the data hasn't arrived yet
  if (!page) {
	return ( <div className="container mx-auto text-center pt-12">
				<button type="button" class="bg-black inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white cursor-not-allowed" disabled>
				  <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
					<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
					<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
				  </svg>
					  Loading
			  </button>
			</div>
	);
  }	

  	const buttons = document.querySelectorAll('.share');
	const resultPara = document.querySelectorAll('.result');
		
	console.log(buttons);
  
	for (const button of buttons) {
		  
	  
	  button.addEventListener('click', () => {
		console.log(button);
		navigator.share({
			url: button.getAttribute('data-url'),
			title: button.getAttribute('data-title'),
			text: button.getAttribute('data-text'),
		  })
		  .then(() =>
			resultPara.textContent = 'MDN shared successfully'
		  )
		  .catch((e) =>
			resultPara.textContent = 'Error: ' + e
		  )
	  });
	}	
	
	
  return (
	<div className="container mx-auto px-4">
	  <div className="flex flex-wrap -mx-3 lg:-mx-6">
	  {page.map(item => (
		  <div className="w-full pt-3 md:pt-6">
		  { /* JSON.stringify(item) */}
			<article className="max-w-xl mx-auto">
			  <div className="feed-item border border-black rounded-lg bg-black">
				<div className="header p-4 flex justify-between items-center">
				  <div className="left flex flex-row items-center">
					<div className="user-name-and-place flex flex-col text-white">
					<span className="text-sm font-bold">{item.title}</span>
					<span className="text-xs font-light text-gray-400"></span>
					</div>
				  </div>
				  <div className="right">
				  </div>
				</div>
				{/* Check the MF exists and there is ONLY one image */
					item.imagesCollection && item.imagesCollection.items.length === 1 &&
					<div className="feed-img relative">
						<img src={item.imagesCollection.items[0].url} alt={item.imagesCollection.items[0].title} /> 
				   </div>
				}
				{/* Check the MF exists and there is MORE THAN one image! */
					item.imagesCollection && item.imagesCollection.items.length > 1 &&
					<Carousel showThumbs={false} dynamicHeight={true}>
					{item.imagesCollection.items.map(item => (
						<div key={item.title}>
							<img
							  src={item.url}
							  alt={item.title}
							/>
							</div>
						)
					  )}
					</Carousel>
				}
				{ item.embedLink &&
					<EmbedHandler embedId={item.embedLink} />
				}			
				{ item.postLink &&
					<div>
						{  item.linkLabel ?
							<a class="px-4 py-2 bg-red-700 text-white block" href={item.postLink}>
									{item.linkLabel}
							</a>
							: 
							<a class="px-4 py-2 bg-red-700 text-white block" href={item.postLink}>
								Find out more -->
							</a>
						}
					</div>
				}
				<div className="card-footer p-4 text-white">
				  <div className="top">
					<div className="caption text-sm">
					  <p className="pb-2">{item.captionBody}</p>
					</div>
				  </div>
				</div>
			  </div>
		  </article>
		</div>
	  ))}
	  </div>
	</div>
  );
}