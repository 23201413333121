import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MobileShare = ({shareBody, shareTitle}) => {
  const handleOnClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `${shareTitle}`,
          text: `${shareBody}`,
          url: document.location.href,
        })
        .then(() => {
          console.log('Successfully shared');
        })
        .catch(error => {
          console.error('Something went wrong sharing this item', error);
        });
    }
  };

  return (
    <Wrapper onClick={handleOnClick}>
      <ShareIcon />
    </Wrapper>
  );
};

const ShareIcon = () => (
  <svg width="30px" height="30px" viewBox="0 0 180 180" version="1.1">
  <title>exit_to_app copy</title>
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Artboard" transform="translate(-1927.000000, -5639.000000)">
      <g id="Group" transform="translate(1897.000000, 5609.000000)">
        <g id="exit_to_app-copy" transform="translate(120.000000, 120.000000) rotate(-90.000000) translate(-120.000000, -120.000000) ">
          <g id="-Round-/-Action-/-exit_to_app" transform="translate(0.000000, 0.000000)">
            <polygon id="Path" points="0 0 240 0 240 240 0 240"></polygon>
            <path d="M107.9,162.9 C111.8,166.8 118.1,166.8 122,162.9 L157.9,127 C161.8,123.1 161.8,116.8 157.9,112.9 L122,77 C118.1,73.1 111.8,73.1 107.9,77 C104,80.9 104,87.2 107.9,91.1 L126.7,110 L40,110 C34.5,110 30,114.5 30,120 C30,125.5 34.5,130 40,130 L126.7,130 L107.9,148.8 C104,152.7 104.1,159.1 107.9,162.9 Z M190,30 L50,30 C38.9,30 30,39 30,50 L30,80 C30,85.5 34.5,90 40,90 C45.5,90 50,85.5 50,80 L50,60 C50,54.5 54.5,50 60,50 L180,50 C185.5,50 190,54.5 190,60 L190,180 C190,185.5 185.5,190 180,190 L60,190 C54.5,190 50,185.5 50,180 L50,160 C50,154.5 45.5,150 40,150 C34.5,150 30,154.5 30,160 L30,190 C30,201 39,210 50,210 L190,210 C201,210 210,201 210,190 L210,50 C210,39 201,30 190,30 Z" id="🔹Icon-Color" fill="#ffffff"></path>
          </g>
        </g>
      </g>
    </g>
  </g>
  </svg>
);

const Wrapper = styled.div`
  height: 40px;
  width: 40px;
`;

export default MobileShare;